<template>
  <div class="main p-b-50">
    <div class="w1200">
      <div class="padc16 font14 color999 flex-left">
        <img src="@/assets/images/icon_27.png" alt="" />
        <span class="m-l-8">当前位置</span>
        <span class="pad8">/</span>
        首页
        <span class="pad8">/</span>
        <span class="colorff9">搜索</span>
      </div>
      <div class="sear">
        <div>
          <Input v-model="value" placeholder="请输入" style="width: 810px">
            <Icon type="ios-search" slot="prefix" />
          </Input>
        </div>
        <div class="m-l-10 m-r-30 font14 color333 hand" @click="searchBtn()">
          搜索
        </div>
        <div class="font14 color999">
          共 {{ total }} 个课程，每页显示{{ per_page }}个课程
        </div>
      </div>
      <div class="m-t-28 m-b-30 m-con">
        <div class="flex-wrap">
          <div
            class="mc-li m-r-20 m-b-20"
            v-for="(item, index) in menuNav"
            :key="index"
          >
            <CourseItem
              imgw="280"
              imgh="175"
              @faBtn="faBtn(item)"
              :coverImg="item.coverImg"
              :title="item.title"
              :doctoName="item.teacher"
              :introduce="item.label"
              :price="item.salePrice"
              :original="item.markPrice"
              :playNum="item.realPlayCount"
              :free="item.free == 1 ? true : false"
            >
            </CourseItem>
          </div>
        </div>
        <div class="text-c" v-if="menuNav.length != 0">
          <Page
            @on-change="change"
            :total="total"
            :page-size="per_page"
            :current="current_page"
            prev-text="上一页"
            next-text="下一页"
          />
          <slot>
            <span class="ml20"
              >共有 <span class="co36">{{ total }}</span> 条数据</span
            >
          </slot>
        </div>
      </div>
      <div class="text-c" v-if="menuNav.length == 0">
        <img src="../assets/images/img_7.png" alt="" />
        <p class="text-c">暂无课程</p>
      </div>
    </div>
  </div>
</template>

<script>
import CourseItem from "../components/CourseItem.vue";
import { search } from "@/api/home";
export default {
  data() {
    return {
      value: "",
      menuNav: [],
      total: 0, //数据总数
      order_page: 1, //页码
      current_page: 1, //当前页码
      per_page: 0, //每页显示条数
      last_page: 0, //最后一页
    };
  },
  components: {
    CourseItem,
  },
  mounted() {
    let user = localStorage.getItem("userInfo");
    this.user = JSON.parse(user);
  },

  methods: {
    change(v) {
      this.order_page = v;
      this.searchBtn();
    },
    faBtn(item) {
      this.$router.push({
        path: "/CourseDetail",
        query: item,
      });
    },
    searchBtn() {
      if (this.value) {
        
        search({
          keyword: this.value,
          merchants_id: this.user.merchants_id,
          pageNum: this.order_page,
        }).then((res) => {
          this.menuNav = res.data.data;
          this.total = res.data.total;
          this.current_page = res.data.current_page;
          this.per_page = res.data.per_page;
          this.last_page = res.data.last_page;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: 600px;
  background: #f8f8f8;
}

.sear {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  background: #ffffff;
  border-radius: 6px;
}
</style>